export const realtorData = {
  email: 'chf.gi@unne.cl',
  phone:'56988278865',
};

export const footerData = {
  email: 'chf.gi@unne.cl',
  phone:'988278865',
  wsp:'988278865',
}
